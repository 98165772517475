<template>
  <!--内容-->
  <div class="page-schemeDetail" style="height: 100Vh">
    <div class="gk-container gk-clear">
      <div class="detail-content">
        <h1>{{ detail.title }}</h1>
        <div class="desc">
          <div class="tag">{{ detail.tags }}</div>
        </div>
        <div class="static">
            <span><img src="/static/iconImg/icon-view02.png" alt="">{{ detail.view }}</span>
            <span><img src="/static/iconImg/icon-support.png" alt="">{{ detail.very_good }}</span>
            <span><img src="/static/iconImg/icon-comment.png" alt="">{{ detail.comment }}</span>
          </div>
        <div class="detail">
<!--          <div v-html="detail.content"></div>-->
          <pdf_viewer :pdfUrl="detail.file[0]"></pdf_viewer>
        </div>
        <!--   点赞-->
        <div class="btn-like" @click="veryGood"><img src="/static/iconImg/icon-support2.png" alt="点赞"></div>
        <!--  举报等操作   -->
        <div class="detail-op-btns">
          <div class="left"><img src="/static/iconImg/icon-danger.png" alt="">举报</div>
          <div class="right">
            <span @click="favoriteArticle" class="this-mouse-pointer"><img src="/static/iconImg/icon-favarite.png" alt="">{{favorite_txt}}</span>
            <span class="this-mouse-pointer"><img src="/static/iconImg/icon-share.png" alt="">分享</span>
          </div>
        </div>
        <!--  评论区域-->
<!--        <div class="comment-mod">
          <div class="tit">评论</div>
          <div class="comment-cont">
            <img class="advater" src="/static/upload/advater.png" alt="">
            <div class="det">
              <input type="text" placeholder="说两句吧~">
              <span>发布</span>
            </div>
          </div>
        </div>-->
      </div>
      <div class="side-bar">
        <!-- 作者信息-->
        <div class="user-info">
          <router-link :to="'/utterer/'+detail.user_id">
            <div class="info">
              <img :src="user.avatar" :alt="user.nickname">
              <div class="text">
                <div class="name">{{ user.nickname }}</div>
                <span>{{ user.residecity }} ｜ {{ user.profession }}</span>
              </div>
            </div>
          </router-link>
          <div class="desc">
            <span v-for="(m,index) in authorStatistics" :key="index">{{ m.name }}<i>{{ m.num }}</i></span>
          </div>
          <div class="btns">
            <span class="btn01 this-mouse-pointer" @click="attentionUser">{{attention_txt}}</span>
<!--            <span class="btn02 this-mouse-pointer">定制方案</span>-->
          </div>
        </div>
        <!--   会员价格-->
<!--        <div class="price-mod">
          <div class="price">
            <span class="txt01">价格：</span>
            <span class="price01">¥19.9</span>
            <div class="vip">
              <span>作品定价</span>
              <span>¥9.9</span>
            </div>
          </div>
          <div class="vip-scheme">
            <div class="tit">开通方案SVIP，此方案免费</div>
            <div class="desc">10w+方案免费下，日更300+</div>
            <div class="btn">立即开通SVIP</div>
          </div>
          <div class="vip-btn">
            <span>付费看完</span>
            <span>付费下载</span>
          </div>
        </div>-->
        <!--   喜欢该作品的还看了 列表-->
        <div class="relate-list">
          <div class="tit">喜欢该作品的还看了</div>
          <ul>
            <li v-for="(m,index) in like_data" v-bind:key="index">
              <div class="pic">
                <router-link :to="'/detail/'+m.id">
                  <img :src="m.img" :alt="m.title">
                </router-link>
              </div>

              <div class="txt">
                <h3><router-link :to="'/detail/'+m.id">{{ m.title }}</router-link></h3>
                <div class="price"><span>作品定价</span>¥{{ m.price }}</div>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import article from "@/api/article";
import user from "@/api/user";
import pdf_viewer from "@/components/pdf_viewer";

export default {
  name: "detail",
  components: {pdf_viewer},
  data() {
    return {
      id: 0,
      detail: {
        file:[],
        user_id:0
      },
      user:{
        username: "用户名",
        nickname: "昵称",
        avatar: "https://3x.thisadmin.cn/static/images/tx.jpg",
        gender: "男"
      },
      attention_txt:"  + 关注",
      favorite_txt:"收藏",
      like_data: [],
      authorStatistics:[],
      loading: false,
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  watch: {
    '$route.params.id': {
      immediate: true, // 立即触发
      handler(newVal, oldVal) {
        // 当id变化时，你可以在这里执行你的逻辑
        console.log('id changed from ' + oldVal + ' to ' + newVal);
        if(newVal!=undefined && newVal>0){
          this.id = newVal;
          this.getDetail();
        }
      }
    }
  },
  methods: {
    //调取 用户 作品，关注，收藏 统计
    getAuthorStatistics() {
      this.loading = true
      article.getAuthorStatistics({
        uid: this.detail.user_id
      }).then(res => {
        if (res) {
          this.authorStatistics = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //登录框
    showLogin() {
      this.$store.dispatch('toggleLoginModal');
    },
    getDetail() {
      article.getDetail({
        id: this.id
      }).then(res => {
        if (res) {
          this.detail = res.data;
          this.user = res.data.user;
          this.attention_txt = res.data.attention_txt;
          this.favorite_txt = res.data.favorite_txt;
          this.getUserLike();
          this.getAuthorStatistics();
        }
      })
      window.scroll(0,0);
    },
    //点赞
    veryGood() {
      article.veryGood({
        id: this.id
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          //this.detail = res.data;
        }
      })
    },
    //关注作者
    attentionUser() {
      user.attentionUser({
        uid: this.detail.user_id
      }).then(res => {
        if (res) {
          this.attention_txt=res.data.is_delete==1?" + 关注":" 已关注";
          //this.$message.success(res.msg)
        }
      })
    },
    //收藏作品
    favoriteArticle() {
      user.favoriteArticle({
        id: this.id
      }).then(res => {
        if (res) {
          this.favorite_txt=res.data.is_delete==1?"收藏":"已收藏";
          //this.$message.success(res.msg)
        }
      })
    },
    //推荐看
    getUserLike() {
      article.getListRows({
        cid: this.detail.cid
      }).then(res => {
        if (res) {
          this.like_data = res.data;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.this-mouse-pointer {
  cursor: pointer;
}
</style>